import React from "react";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import NavigationBar from "./components/NavigationBar";
import Home from "./pages/Home";
import Services from "./pages/Services"
import Opening from "./pages/Opening"
import Imprint from "./pages/Imprint"

import './App.css';
import Products from "./pages/Products";

export default function App() {

  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/opening" element={<Opening />} />
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
    </Router>
  )
};