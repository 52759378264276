import React from "react";
import { Carousel } from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";



import pulver from '../assets/colors/Pflanzenpulver und Blüte2.jpg';
import farbpallete from '../assets/colors/Produkt_satuscolor_Farbpalette.jpg';
import Braun from '../assets/colors/satuscolor_Basis Braun.png';
import Gold from '../assets/colors/satuscolor_Basis Gold.png';
import Hell from '../assets/colors/satuscolor_Basis Hell.png';
import Kupfer from '../assets/colors/satuscolor_Basis Kupfer.png';
import Neutral from '../assets/colors/satuscolor_Basis Neutral.png';
import Rot from '../assets/colors/satuscolor_Basis Rot.png';
import Rubin from '../assets/colors/satuscolor_Basis Rubin Dunkel.png';

import img_0 from '../assets/careProducts/CULUMNATURA_Plakat_facialis.jpg';
import img_2 from '../assets/careProducts/Produktpalette_Pflege.jpg';
import img_3 from '../assets/careProducts/Produktpalette_sublimare.jpg';
import img_4 from '../assets/careProducts/Produktpalette.jpg';
import img_5 from '../assets/careProducts/Werkzeug_CULUMNATURA_Bürste.jpg';

import './Products.css'; // Importiere deine CSS-Datei

const Products = () => {

    const colors = [
        {
            imgelink: pulver
        },
        {
            imgelink: farbpallete
        },
        {
            imgelink: Braun
        },
        {
            imgelink: Gold
        },
        {
            imgelink: Hell
        },
        {
            imgelink: Kupfer
        },
        {
            imgelink: Neutral
        },
        {
            imgelink: Rot
        },
        {
            imgelink: Rubin
        },
    ];

    const [colorActive, setColorActive] = React.useState(
        pulver
    );

    const careProducts = [
        {
            imgelink: img_2
        },
        {
            imgelink: img_3
        },
        {
            imgelink: img_4
        },
        {
            imgelink: img_5
        },
    ];

    const [careProductsActive, setCareProductsActive] = React.useState(
        img_2
    );


    return (
        <div className="mx-auto max-w-screen-md py-10">
            <Typography variant="h2" style={{ color: "#5aba82" }} className="mb-5 text-center baskervville-regular mx-5">
                Pflanzenfarben
            </Typography>
            <div>
                <img
                    className="h-auto rounded-lg object-cover object-center md:h-[480px]"
                    src={colorActive}
                    alt=""
                />
            </div>
            <div className="grid grid-cols-4 gap-4">
                {colors.map(({ imgelink }, index) => (
                    <div key={index}>
                        <img
                            onClick={() => setColorActive(imgelink)}
                            src={imgelink}
                            className="h-20 cursor-pointer rounded-lg object-cover object-center"
                            alt="gallery-image"
                        />
                    </div>
                ))}
            </div>
            <Typography variant="h2" style={{ color: "#5aba82" }} className="mt-4 mb-5 text-center baskervville-regular mx-5">
                Pflegeprodukte
            </Typography>
            <div>
                <img
                    className="h-auto rounded-lg object-cover object-center md:h-[480px]"
                    src={careProductsActive}
                    alt=""
                />
            </div>
            <div className="grid grid-cols-4 gap-4">
                {careProducts.map(({ imgelink }, index) => (
                    <div key={index}>
                        <img
                            onClick={() => setCareProductsActive(imgelink)}
                            src={imgelink}
                            className="h-20 cursor-pointer rounded-lg object-cover object-center"
                            alt="gallery-image"
                        />
                    </div>
                ))}
            </div>
        </div>

    );
}


export default Products;
