import React from "react";

import { Typography, Card } from "@material-tailwind/react";

import Logo from '../assets/GesundesHaar_Logo.png';
import { Container } from "postcss";

const Home = () => {

    return (
        <div className="mx-auto max-w-screen-md py-10">
            <Typography variant="h2" style={{ color: "#5aba82" }} className="mb-2 text-center baskervville-regular">
                Herzlich willkommen!
            </Typography>
            <img
                alt="nature"
                className="h-[16rem] w-full object-contain object-center animate-pulse"
                src={Logo}
                backgroundcolor="green"
                style={{ position: "relative", zIndex: "-50" }}
            />
            <Typography color="blue-gray" className="mt-10 mx-5 font-normal text-xl text-center baskervville-regular">
                Mein Name ist Yuliya Baron und es freut mich dass sie meine Webseite besuchen.<br />
                Ich bin leidenschaftliche Meisterfriseurin mit über 10 jahren Berufserfahrung.<br />
                Bei mir erwartet Sie nicht nur eine professionelle Haarbehandlung, sondern auch eine einladende Atmosphäre, in der Sie sich entspannen und die natürlichen Aromen meiner Produkte erleben können.<br />
                Von biologisch abbaubaren Shampoos bis hin zu Haarfärbemitteln auf pflanzlicher Basis – ich verwenden nur Produkte, die die Umwelt respektieren und Ihren Haaren Gesundheit verleihen.
            </Typography>
            <Typography color="blue-gray" className="mt-10 mx-5 font-normal text-xl text-center baskervville-regular">
                Besuchen Sie mich doch in der Hohenhewenstr. 5 in 78224 Singen.<br /> Kommen Sie gerne vorbei! <br/><br/><br/><b>Gesundes Haar</b> Naturfriseur
            </Typography>
        </div>
    )
};

export default Home;
