import React from "react";
import { Typography } from "@material-tailwind/react";

const Services = () => {
  return (
    <div className="mx-auto max-w-screen-md py-10">
      <Typography variant="h2" style={{ color: "#5aba82" }} className="mb-2 text-center baskervville-regular mx-5">
        Dienstleistungen
      </Typography>
      <Typography color="blue-gray" className="mt-10 font-normal text-xl text-center baskervville-regular">
          <ul class="list-inside list-disc">
            <li>Haarfärbung mit Pflanzenfarbe</li>
            <li>Frauenhaarschnitte</li>
            <li>Männerharschnitte</li>
            <li>Kinderhaarschnitte</li>
            <li>Waschen/Fönen</li>
            <li>Wimpern und Augenbraun Färbung</li>
          </ul>
      </Typography>
      <Typography variant="h3" style={{ color: "#5aba82" }} className=" mt-10 mb-2 text-center baskervville-regular mx-5">
        Entspannende Atmosphäre
      </Typography>
      <Typography color="blue-gray" className="font-normal text-xl text-center baskervville-regular mx-5">
        <div>Genießen Sie eine entspannte und einladende Atmosphäre während Ihres Aufenthalts. Ich möchte, dass sich jeder Besuch wie ein Wellnesserlebnis für Ihr Haar anfühlt.</div>
        <div>Nutzen Sie die Gelegenheit und vereinbaren Sie jetzt Ihren individuellen Termin, um Ihr Haar in professionellen Händen zu verwöhnen. </div>
      </Typography>
    </div>
  )
};

export default Services;