import React from "react";
import { Typography } from "@material-tailwind/react";

const Opening = () => {
    return (
        <div className="mx-auto max-w-screen-md py-10">
            <Typography variant="h2" style={{color: "#5aba82" }} className="mb-2 text-center baskervville-regular">
                Öffnungszeiten
            </Typography>
            <Typography color="blue-gray" className="font-normal text-xl text-center baskervville-regular mx-5">
                Um einen Termin zu vereinbaren rufen Sie bitte meine Mobilfunknummer unter <div className="font-normal font-mono">+49 15566336802</div> an.
                Bitte beachten Sie: Falls ich persönlich nicht sofort antworten kann, hinterlassen Sie mir bitte eine kurze Nachricht auf meinem Anrufbeantworter.
                Ich freue mich darauf, Sie zurückzurufen und mich persönlich um Ihr Anliegen zu kümmern.
                Vielen Dank für Ihr Verständnis.
            </Typography>
            <Typography variant="h2" style={{color: "#5aba82" }} className="mt-5 mb-2 text-center baskervville-regular">
                Standort
            </Typography>
            <Typography color="blue-gray" className="font-normal text-xl text-center baskervville-regular mx-5">
                Sie finden meinen Friseursalon in der Hohenhewenstr. 5, 78224 Singen
            </Typography>
            <div className="flex justify-center mt-5 mx-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2658.555978164152!2d8.835860928010218!3d47.76805409357601!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a7d3c973f294f%3A0xb611247c76223204!2sHohenhewenstra%C3%9Fe%205%2C%2078224%20Singen%20(Hohentwiel)!5e0!3m2!1sde!2sde!4v1705608130640!5m2!1sde!2sde" width="600" height="450" style={{ border: "2px solid green", allowFullScreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
            </div>
        </div>
    )
};

export default Opening;