import React from "react";

import { Typography } from "@material-tailwind/react";

const Imprint = () => {
    return (
        <div className="mx-auto max-w-screen-md py-10">
            <Typography variant="h2" style={{ color: "#5aba82" }} className="mb-2 text-center baskervville-regular mx-5">
                Impressum und Datenschutz
            </Typography>
            <Typography variant="h3" color="blue-gray" className="mt-5 text-center baskervville-regular mx-5">
                Impressum
            </Typography>
            <Typography color="blue-gray" className="mt-5 font-normal text-xl text-center baskervville-regular mx-5">
                <div>Gesundes Haar</div>
                <div>Friseurmeisterin Yuliya Baron</div>
                <div>Eingetragen in der Handwerkskammer Konstanz</div>
                <div>Hohenhewenstrasse 5</div>
                <div>78224 Singen (Hohentwiel)</div>
                <div className="font-normal font-mono">Telefon: +49 15566336802</div>
                <div>E-Mail: gesundes.haar.naturfriseur@gmail.com</div>
                <div>Termine werden nicht per E-Mail angenommen!</div>
                <div>Nach § 19 UstG wird keine Umsatzsteuer berechnet.</div>
            </Typography>
            <Typography variant="h3" color="blue-gray" className="mt-5 text-center baskervville-regular mx-5">
                Datenschutz
            </Typography>
            <Typography color="blue-gray" className="mt-5 text-xl text-center baskervville-regular mx-5">
                <div>1. Beim Besuch der Webseite</div>
                <div>Beim Besuchen dieser Webseite, übermittelt Ihr Webbrowser systembedingt gewisse Informationen an den Server.</div>
                <div>Zur Diensterbringung werden folgende Informationen durch die Rechtsgrundlage für die Datenverarbeitung Art. 6 Abs. 1 S.1 lit. f DSGVO vom Webserver verarbeitet:</div>
                    <ul className="list-inside list-disc">
                        <li>Die Domain die aufgerufen wird</li>
                        <li>Die IP-Adresse deines Rechners</li>
                        <li>Datum und Uhrzeit des Zugriffs</li>
                        <li>Art des HTTP-Request</li>
                        <li>Die aufgerufene URL</li>
                        <li>Webseite von dem aus der Zugriff erfolgte</li>
                        <li>Den HTTP-Statuscode, den verwendeten Browser und ggf. das Betriebssystem Ihres Rechners</li>
                    </ul>
                <div>Die genannten Daten werden für folgende Zwecke verarbeitet:</div>
                    <ul className="list-inside list-disc">
                        <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite</li>
                        <li>Auswertung der Systemsicherheit und -stabilität</li>
                    </ul>
                <div>2. Cookies</div>
                <div>Die Nutzung der Webseite ist ohne Cookies möglich.</div>
                <div>3. Betroffenenrechte</div>
                <div>Sie hast das Recht</div>
                    <ul className="list-inside list-disc">
                        <li>gemäß Art. 15 DSGVO: Auskunft über deine verarbeiteten personenbezogenen Daten zu verlangen</li>
                        <li>gemäß Art. 16 DSGVO: unverzüglich die Berichtigung oder Vervollständigung Ihrer gespeicherten personenbezogenen Daten zu verlangen</li>
                        <li>gemäß Art. 17 DSGVO: die Löschung Ihrer gespeicherten personenbezogenen Daten zu verlangen</li>
                        <li>gemäß Art. 18 DSGVO: die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen</li>
                        <li>gemäß Art. 20 DSGVO: deine personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten</li>
                        <li>gemäß Art. 77 DSGVO: sich bei einer Aufsichtsbehörde zu beschweren</li>
                        <li>gemäß Art. 7 Abs. 3 DSGVO: Ihre einmal erteilte Einwilligung jederzeit zu widerrufen</li>
                    </ul>
                <div>4. Widerspruchsrecht</div>
                <div>Sofern Ihrer personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen.</div>
                <div>5. Externe Links</div>
                <div>Für Inhalte anderer Webseiten, auf die Sie über unsere Links gelangen können, übernehmen wir keine Verantwortung.</div>
                <div>6. Aktualität</div>
                <div>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand von Juni 2021</div>
                <div>7. Adressverarbeitung</div>
                <div>Alle die auf dieser Webseite angegebenen Kontaktinformationen von uns inklusive etwaiger Fotos dienen ausdrücklich nur zu Informationszwecken bzw. zur Kontaktaufnahme. Sie dürfen insbesondere nicht für die Zusendung von Werbung, Spam und ähnliches genutzt werden.</div>
            </Typography>
        </div>
    )
};

export default Imprint;